import React from "react";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify/react";
// import { createListItems } from "../../utils/constants";

export const PaymentCancelConfirmation = ({
  plan,
  handlePayment,
  setShowConfirmationModel,
}) => {
  return (
    <Modal
      show={true}
      backdrop="static"
      centered
      keyboard={false}
      dialogClassName="custom-modal-width full-screen-mobile"
    >
      <Modal.Header
        style={{
          width: "100%",
          borderBottom: "none",
        }}
      >
        <Modal.Title
          style={{
            width: "100%",
          }}
        >
          <div className="d-flex justify-content-between w-100 align-items-center">
          <div>
          <Icon
              icon="fluent-mdl2:chrome-back"
              className="back-btn"
              width={20}
              onClick={() => setShowConfirmationModel(false)}
            />
          </div>
            <div><b>Cancel your subscription?</b></div>
            <div >
            <Icon
              icon="basil:cross-outline"
              className="cross-btn-header"
              width={30}
              onClick={() => setShowConfirmationModel(false)}
            />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="box-confirmation">
          <div>
            <div className="total-title">TOTAL AMOUNT</div>
            <div>
              <span className="confirmation-amount">₹ {plan.price}</span>
            </div>
          </div>
          <div className="confirmation-hr-line"></div>
          <div className="last-section">
          <div>
            <div className="for-subscription-title">FOR SUBSCRIPTION</div>
            <div className="mt-3 d-flex mb-5 gap-2"
            >
             {(() => {
          switch (plan.type) {
            case "basic":
              return (
                <div className="paid-box-icon h-min">
                  <Icon icon="system-uicons:diamond" width={30} />
                </div>
              );
            case "premium":
              return (
                <div className="paid-box-icon h-min">
                  <Icon icon="octicon:goal-24" width={30} />
                </div>
              );
            case "free":
              return (
                <div className="free-box h-min">
                  <Icon icon="ph:crown-simple" color="#ffff" width={30} />
                </div>
              );
            default:
              return (
                <div className="free-box h-min">
                  <Icon icon="ph:crown-simple"  color="#ffff" width={30} />
                </div>
              );
          }
        })()}
        <div className="">
        <div className=" ">
            <div className="planType-title" style={{
              fontSize:'18px',
              color: '#003285',

            }}>{plan?.name}</div>
          </div>
          <div className="d-flex align-items-center">
            <Icon icon="ic:round-currency-rupee" width={20} />{" "}
            <span
              style={{
                fontSize: "16px",
              }}
            >
              {plan?.price}
            </span>{" "}
            {plan?.frequency!=='free' && <span
              style={{
                marginLeft: "5px",
                fontSize: "16px",
              }}
            >
              per {plan?.frequency}
            </span>}
          </div>
        </div>
              {/* {createListItems(plan?.description)} */}
            </div>
          </div>
          <div className="">
            <button className={`buyNow-btn`}  onClick={() => {
                    handlePayment(plan);
                  }}>
              <div className="d-flex justify-content-center align-items-center gap-3">
                <div
               
                >
                  Yes, Cancel it
                </div>
              </div>
            </button>
          </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
