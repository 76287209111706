import React, { useEffect, useState } from 'react';
import { Button, Offcanvas, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Membership } from '../services/employeer/subscription';
import { toast } from 'react-toastify';
import { Icon } from "@iconify/react";
import moment from "moment/moment";
import { PaymentCancelConfirmation } from './models/payment-cancel-confirmation';

const PopupSidebar = ({show, handleClose}) => {
    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCancelModel, setShowCancelModel]= useState(false);
    const token = localStorage.getItem("EMP_TOKEN"); // Retrieve token from localStorage
    const fetchPlans = async () => {
  
  
      try {
        const result = await Membership.getCurrentActivePlan();
        setPlans(result.data);
      } catch (error) {
        console.error("Failed to fetch plans:", error);
        toast.error(error.response, {
          position: "top-right",
          autoClose: 3000,
        });
        if (
          error?.response?.status === 422 ||
          error?.response?.status === 401
        ) {
          window.location.href = "/";
        }
      } finally {
        setLoading(false);
      }
    };
    useEffect(() => {
     
  
      fetchPlans();
    }, [navigate, token]);
  
    const createListItems = (description) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(description, "text/html");
  
      // Check if there are list items in the description
      const listItems = doc.querySelectorAll("li");
      if (listItems.length > 0) {
        return Array.from(listItems).map((item, index) => (
          <li
            key={index}
            style={{
              listStyleType: "none", // Remove bullet points
              paddingLeft: "10px", // Adjust padding if needed
              margin: "0", // Remove any margin
            }}
          >
            {item.textContent}
          </li>
        ));
      } else {
        return <div>Plan</div>;
      }
    };

    const handleEmployerPlanCancel= async ()=>{
      await Membership.cancelSubscription().then(()=>{
        toast.success("Your plan has been successfully cancelled.", {
          position: "top-right",
          autoClose: 3000,
      });
      fetchPlans();
      setShowCancelModel(false);
      // window.location.href='/employer/dashboard';
      }).catch(()=>{
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 3000,
      });
      })

    }
  
 return (
    <>
 {/* Blur Overlay */}
 {show && <div className="blur-overlay" onClick={handleClose}></div>}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='my-plan-title'>My Plan</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            alignContent: 'center',
            alignSelf: 'center',
        }}>
            {loading ? (
              <div className="col-12">
                <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : plans?.planDetails?.name ? (
              <div className=''>
                <div className="d-flex flex-column align-content-center align-items-center section-my-plan-sidebar">
                  <div className="free-box-my-plan-emp d-flex justify-content-center">
                  {(() => {
            switch (plans?.planDetails.frequency.toLowerCase()) {
              case "monthly":
                return (
                  <div >
                    <Icon icon="ph:crown-simple" width={30} color='#14A6BA'/>
                  </div>
                );
              case "quarterly":
                return (
                  <div >
                    <Icon icon="octicon:goal-24" width={30} color='#14A6BA'/>
                  </div>
                );
              case "free":
                return (
                  <div >
                    <Icon icon="ph:crown-simple"  width={30} color='#14A6BA'/>
                  </div>
                );
              case "yearly":
                return (
                  <div >
                    <Icon icon="system-uicons:diamond" width={30} color='#14A6BA'/>
                  </div>
                );
              default:
                return (
                  <div >
                    <Icon icon="ph:crown-simple"  width={30} color='#14A6BA'/>
                  </div>
                );
            }
          })()}

                  </div>
                  <div>
                    <div className="d-flex flex-column align-items-center mt-5 gap-3">
                    <div>Current Plan</div>
                      <div className="planType-title">
                        {plans?.planDetails?.name}
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-3 justify-content-center">
                    <div className='d-flex align-items-center'>
                      <Icon icon="ic:round-currency-rupee" width={24} />{" "}
                      <span
                       className='my-plan-price'
                      >
                        {plans?.planDetails?.price ?? 0}
                      </span>
                      </div>
                    </div>
                  </div>
                  <div className="plan-end-date-section p-2">
                  <div style={{
                    padding:'3px',
                    backgroundColor:'#ffff',
                    borderRadius:'100%'
                  }}>
                  <Icon icon="ph:info-fill" width={24} color='#8E8E8E'/>{" "}</div>
                  
                    <div className='jobseeker-plan-type'>
                    Your Plan will end on {" "}
                      {moment(plans?.endDate).format('D MMMM, YYYY')}
                    </div>
                  </div>
                  {/* <div className="hr-line-subscription"></div> */}
                  {/* <div
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {createListItems(plans.planDetails?.description)}
                  </div> */}
                  <button className={`buyNow-btn mt-5 w-80`} 
          onClick={() => window.location.href='/employer/subscriptions'}
          >
            Change Plan
          </button>
          <button className={`cancel-btn mt-2 w-80`} 
          onClick={() => {setShowCancelModel(true); handleClose();}}
          >
            Cancel Plan
          </button>
                </div>
              </div>
            ) : (
              <div className="col-12 text-center">
                <h5>No Plan Found</h5>
                <button
                  className="btn btn-primary"
                  onClick={() => window.location.href='/employer/subscriptions'}
                >
                  Subscribe Now
                </button>
              </div>
            )}
          </div>
         
        </Offcanvas.Body>
      </Offcanvas>
      {showCancelModel === true && <PaymentCancelConfirmation
            plan={plans?.planDetails}
            setShowConfirmationModel={setShowCancelModel}
            handlePayment={(e)=>{handleEmployerPlanCancel(e)}}
          />}
    </>
  );
};

export default PopupSidebar;
