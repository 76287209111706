import jobSeekerBarCode from '../assets/images/barCode_jobseeker.png';
import employerBarCode from '../assets/images/barCode_employer.png';
const keysLabel={
   ADSBasedRedirection :"adsBasedRedirection"
}
const payStoreLinks={
    jobSeeker:{
        google:'https://play.google.com/store/apps/details?id=com.arbeit',
        barCode: jobSeekerBarCode
    },
    employer:{
        google:'https://play.google.com/store/apps/details?id=com.arbeit_employee_app',
        barCode:employerBarCode
    }
}


export const getGooglePlayStore=()=>{
    console.log(window.location.href, window.location.href.includes('employer'));
    if(window.location.href.includes('employer')){
        return CONSTANT.payStoreLinks.employer;
    } else {
        return CONSTANT.payStoreLinks.jobSeeker;
    }
}

const titles={
    Subscription:'Subscription',
    ChooseAPlanForBusiness:'Choose a plan that suits your business',
    BelowAreCurrentJob:'Below are the current Job Posting Plans',
    Loading:'Loading...'
}

const localStorageVariables={
    EMP_TOKEN:"EMP_TOKEN",
    EMP:"EMP",
    compLogo:"compLogo",
    compName:"compName",
    SEEKER:"SEEKER",
    SEEKER_TOKEN:"SEEKER_TOKEN"
}
export const createListItems = (description) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, "text/html");
  
    // Check if there are list items in the description
    const listItems = doc.querySelectorAll("li");
    if (listItems.length > 0) {
      return Array.from(listItems).map((item, index) => (
        <li
          key={index}
          style={{
            listStyleType: "none", // Remove bullet points
            paddingLeft: "10px", // Adjust padding if needed
            margin: "0", // Remove any margin
          }}
        >
          {item.textContent}
        </li>
      ));
    } else {
      // If plain text, return "plan"
      return <div>Plan</div>;
    }
  };

export const CONSTANT={
    keysLabel,
    payStoreLinks,
    localStorageVariables,
}

export const ConstantValues={
    titles,
}
