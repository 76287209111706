import { axios } from "../../lib/axiosJobseeker";


const getSubscriptionList= async(filter)=>{
    return axios.get('/jobSeeker/subscription-plan?status=true', { params: filter});
}
const getSubscriptionById= async(id)=>{
    return axios.get(`/jobSeeker/subscription-plan/${id}`);
}
const postSubscription= async (payload)=>{
    return axios.post('/jobSeeker/subscription-plan', payload);
}
const updateSubscription= async (id,payload)=>{
    return axios.put(`/jobSeeker/subscription-plan/${id}`, payload);
}
const createOrder = async (id, body)=>{
    return axios.post(`/jobSeeker/subscription/${id}`, body);
}
const getCurrentActivePlan= async ()=>{
    return axios.get(`/jobSeeker/active-subscription`);
}
const cancelSubscription= async ()=>{
    return axios.delete(`/jobSeeker/subscription`);
}
export const Membership={
    getSubscriptionList,
    postSubscription,
    updateSubscription,
    getSubscriptionById,
    createOrder,
    getCurrentActivePlan,
    cancelSubscription
}